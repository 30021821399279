$hyoo_toys $mol_book2
	attr *
		^
		mol_theme \$mol_theme_dark
	toy* $hyoo_toys_toy
	Placeholder null
	plugins /
		<= Hotkey $mol_hotkey
			mod_ctrl true
			key * F?event <=> search?event null
	Filters $mol_page
		title @ \Filters
		body /
			<= Filter_type $mol_switch
				value?val <=> filter_type?val \all
				options <= filter_type_options *string
					all <= filter_type_option_all @ \All types
			<= Filter_size $mol_switch
				value?val <=> filter_size?val \all
				options <= filter_size_options *string
					all <= filter_size_option_all @ \All sizes
			<= Filter_flags $mol_list rows /
				<= Filter_popular $mol_check
					checked?val <=> filter_popular?val false
					title <= filter_popular_title @ \Only popular
			<= Sort $mol_switch
				value?val <=> sort_key?val \price
				options <= sort_items *string
					price <= sort_option_price @ \Sort by price
					size <= sort_option_size @ \Sort by size
					title <= sort_option_title @ \Sort by title
	Catalog $hyoo_toys_catalog
		title @ \Toys for all
		toys <= toys /
		toy* <= toy* -
		filter_type <= filter_type
		filter_size <= filter_size
		filter_popular <= filter_popular
		sort_key <= sort_key
	Details!toy $hyoo_toys_details
		toy <= toy_current null
